<!--
 * @Author: youdedong
 * @Date: 2023-09-01 15:49:40
 * @Description: 
-->
<template>
  <div class="container">
    <Slide/>
   <div class="wrap" id="wrap">
    <router-view/>
   </div>
  </div>
</template>

<script>
import Slide from "@/components/Slide";

export default {
  name: 'Home',
  components: { 
    Slide
  }
}
</script>
<style lang="scss" scoped>
  .container{
   display: flex;
  }
  .wrap{
   width: calc( 100% - 200px);
   height: 100vh;
   overflow-x: hidden;
   overflow-y: auto;
  }
</style>
