<template>
  <div class="Slide">
    <div class="logo">
      <img src="../assets/img/logo1.png" alt="" />
    </div>
    <ul>
      <li v-for="item in nav" :key="item.path" @click="handleNav(item.path)" :class="{ active: item.isSelect }">
        <img :src="item.default" alt="" v-if="!item.isSelect" />
        <img :src="item.select" alt="" v-if="item.isSelect" />
        <p>{{ item.title }}</p>
      </li>
    </ul>
    <p>系统版本V1.0.0</p>
  </div>
</template>

<script setup>
import { inject, ref, onMounted, nextTick, watch } from "vue";
import { useRouter } from "vue-router";
let axios = inject("axios");
let role = ref(""); //role：管理员  general 普通用户
// path就是路由文件中的name,也可以用path作为标识，key就是每个模块的公共路径，isSelect用来控制图标的显示
let nav = ref([
  {
    title: "统计概览",
    path: "/stat",
    key: "/stat",
    isSelect: false,
    default: require("../assets/img/统计概览-默认.png"),
    select: require("../assets/img/统计概览-选中.png"),
  },
  {
    title: "目录填报",
    key: "/filling",
    path: "/filling/FillingList",
    isSelect: true,
    default: require("../assets/img/目录填报-默认.png"),
    select: require("../assets/img/目录填报-选中.png"),
  },
  {
    title: "目录复核",
    path: "/examine/examineList",
    key: "/examine",
    isSelect: false,
    default: require("../assets/img/目录审核-默认.png"),
    select: require("../assets/img/目录审核-选中.png"),
  },
  {
    title: "数据目录",
    path: "/directories/datalist",
    key: "/directories",
    isSelect: false,
    default: require("../assets/img/数据目录-默认.png"),
    select: require("../assets/img/数据目录-选中.png"),
  },
  // {
  //   title: "管理配置",
  //   path: "manageList",
  //   key: "/manage",
  //   isSelect: false,
  //   default: require("../assets/img/管理配置-默认.png"),
  //   select: require("../assets/img/管理配置-选中.png"),
  // },
]);
let curpath = ref(0);
const router = useRouter();
const handleNav = (path) => {
  if (path != "/filling/FillingList") {
    router.push(path);
  } else {
    validatePath();
  }

  setTimeout(() => {
    renderMenu();
  }, 300);
};
let path = ref("/");
const renderMenu = () => {
  path.value = window.location.pathname;
  nav.value = nav.value.map((item) => {
    if (path.value.includes(item.key)) {
      item.isSelect = true;
    } else {
      item.isSelect = false;
    }
    return item;
  });
};

//获取用户角色信息
const getUserInfo = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    role.value = user.userInfo.role;
    if (role.value != "general") {
      //普通用户没有审核页面
      nav.value.push({
        title: "管理配置",
        path: "/manage/manageList",
        key: "/manage",
        isSelect: false,
        default: require("../assets/img/管理配置-默认.png"),
        select: require("../assets/img/管理配置-选中.png"),
      });
    }
    if (role.value == "general") {
      role.value = "";
      nav.value.splice(2, 2);
    }
  }

  renderMenu();
};

//验证数据目录跳转路径
const validatePath = () => {
      let url = `/message/content/check?year=${currentYear.value}`;
      axios.get(url).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.isOpenFill) {
            if (data.isDeclare || data.isCompleted) {
              router.push({ name: "fillSuccess" });
            } else if (data.isHasData) {
              router.push({ name: "FillingList" });
            } else {
              router.push({ name: "movement" });
            }
          } else {
            router.push({ name: "fillSuccess" });
          }
        }
      });
};
watch(
  () => router.currentRoute.value.path,
  (newValue, oldValue) => {
    renderMenu();
  },
  { immediate: true }
);
let currentYear=ref("");
//获取系统年度时间
const getStatusInfo = () => {
  let url = `/setting/getFillState`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      currentYear.value=data.year;
    }
  });
};

onMounted(() => {
  // validatePath();
  getUserInfo();
  getStatusInfo();
});
</script>

<style lang="scss" scoped>
.Slide {
  background: #f6f6f6;
  width: 200px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .logo {
    img {
      display: block;
      width: 100px;
      margin: 0 auto;
      padding: 20px 0;
    }
  }
  ul {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    li {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      cursor: pointer;
      // text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        width: 26px;
        height: 26px;
        margin: 0 auto;
      }
      p {
        white-space: nowrap;
        line-height: 30px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #111111;
        margin-top: 10px;
      }
    }
    li.active {
      background: #fff;
      border-radius: 8px;
      p {
        font-weight: bold;
        color: #c12a21;
      }
    }
  }
  > P {
    text-align: center;
    margin-bottom: 10px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 30px;
    letter-spacing: 0px;
    color: #888888;
  }
}
</style>
